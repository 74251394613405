/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      children: "Getting Started"
    }), "\n", _jsx(_components.h3, {
      children: "Install xstyled"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "npm install styled-components @xstyled/styled-components\n"
      })
    }), "\n", _jsxs(_components.h3, {
      children: ["Setup ", _jsx(_components.code, {
        children: "theme"
      }), " and ", _jsx(_components.code, {
        children: "Preflight"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "// App.js\nimport {\n  defaultTheme,\n  ThemeProvider,\n  Preflight,\n} from '@xstyled/styled-components'\n\nconst theme = {\n  ...defaultTheme,\n  // Customize your theme here\n}\n\nexport default function App() {\n  return (\n    <ThemeProvider theme={theme}>\n      <Preflight />\n      {/* ... */}\n    </ThemeProvider>\n  )\n}\n"
      })
    }), "\n", _jsx(_components.h3, {
      children: "Write your first component"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { x } from '@xstyled/styled-components'\n\nfunction Button(props) {\n  return <x.button bg=\"blue-500\" {...props} />\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
